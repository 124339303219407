export const MandalaRing = (locationX, locationY, size, shape, objectSize, objectCount, startDegrees) => { 
    return { 
        locationX: locationX, 
        locationY: locationY,
        size: size,
        shape: shape,
        objectSize: objectSize,
        objectCount: objectCount,
        startDegrees: startDegrees
     } 
}

import React from "react";
import { Button, Col, InputNumber, Row, Slider, Select, Space, Tabs } from 'antd';
const HomePage = () => {


return (
  <>
    <div class="first-fold-container">
        <div class="first-fold-inner">
            <h1 class="main-header">
                Infinite coloring pages for everyone  
            </h1>
            <h3 class="secondary-header">
                Infinite Coloring gives you access to infinite number of interesting and immersive pages to color 
            </h3>
            <Button type="primary">
                Get started
            </Button>
            <Button>
                Read more
            </Button>
        </div>
    </div>
    <div class="main-content">
        <div class="main-content-container">
        <div class="highlights-bar">
            <div class="highlights-bar-item">
                <h4>FREE</h4>
                <p>Infinite Coloring is and will stay free as everyone deserves equal</p>
            </div>
        </div>
        <div class="highlights-bar">
            <div class="highlights-bar-item">
                <h4>Relax</h4>
            </div>
            <div class="highlights-bar-item">
                <h4>Improve focus</h4>
            </div>
            <div class="highlights-bar-item">
                <h4>Improve sleep</h4>
            </div>
            <div class="highlights-bar-item">
                <h4>Mindfulness</h4>
            </div>
            <div class="highlights-bar-item">
                <h4>Relieve stress</h4>
            </div>
            <div class="highlights-bar-item">
                <h4>Reduce Anxiety</h4>
            </div>
        </div>
        <Row className="content-row" >
            <Col className="text-container" xs={24} md={16}>
                <h2>Coloring pages for adults help you relax and relieve stress</h2>
                <p>
                    Coloring pages used to for kids, but that's no more. 
                    People of all ages can and should enjoy the benefits of coloring. 
                </p>
                <p>
                    Studies show that coloring books are a great way to quiet your mind and relax both body and brain. When you are coloring you'll focus on that paper infront of you.
                    This can be therapeutic and induce meditative state. 
                </p>
            </Col>
            <Col className="picture-container" xs={24} md={8}>
                <img class="content-row-img" src={require('./../assets/rocks.jpg')} />
            </Col>
        </Row>
        <Row className="content-row">
            <Col className="text-container" xs={24} md={16}>
                <h2>Coloring pages for adults help you sleep</h2>
                <p>
                    Relaxing effect of coloring has shown to improve sleep when coloring happens before bed. 
                    Unline phones, coloring pages do not emit blue light nor have built in addicting algorithms.  
                </p>
                <p>
                    Better sleep affects every aspect of your life from physical to psychological health, daily energy level and ability to learn new.
                </p>
            </Col>
            <Col className="picture-container" xs={24} md={8}>
                <img class="content-row-img" src={require('./../assets/rocks.jpg')} />
            </Col>
        </Row>
        <Row className="content-row">
            <Col className="text-container" xs={24} md={16}>
                <h2>Coloring pages for adults help you focus</h2>
                <p>
                    Coloring books activate yor frontal lobe, meaning your brain is actively problem solving and organizing the way your brain is built to work. 
                    Regular coloring sessions help you maintain the ability to focus on one thing. 
                </p>
                <p>
                    Smartphones with endless pictures and short video clips are pulling our attention apart. Coloring helps you get your focus back.
                </p>
            </Col>
            <Col className="picture-container" xs={24} md={8}>
                <img class="content-row-img" src={require('./../assets/rocks.jpg')} />
            </Col>
        </Row>
        <div class="cta-bar">
            <div class="highlights-bar-tem">
                <Button type="default">Read more about coloring</Button>        
            </div>
            <div class="highlights-bar-tem">
                <Button type="primary">Get started</Button>        
            </div>
        </div>
            
        </div>
    </div>
  </>
);
}
export default HomePage;
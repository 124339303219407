import React from "react"
import { ConfigProvider } from 'antd';
import { Routes ,Route, Link } from 'react-router-dom';
// We will create these two pages in a moment
import HomePage from "./pages/HomePage"
import FractalGenerator from "./pages/FractalGenerator"
import MandalaGenerator from "./pages/MandalaGenerator"
import PictureConverter from "./pages/PictureConverter"
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';


import { Button, Col, InputNumber, Row, Slider, Select, Space, Menu, Layout } from 'antd';
const { Header, Footer, Sider, Content } = Layout;
export default function App() {
  const {  useState  } = React;
 
  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };
/*
<Menu.Item key="2">
            <Link to="/fractal" className="nav-text">Fractals</Link>
        </Menu.Item>
        <Menu.Item key="3">
            <Link to="/picture" className="nav-text">Picture converter</Link>
        </Menu.Item>


          <Route path="/fractal" element={<FractalGenerator/>} />
          <Route path="/picture" element={<PictureConverter/>} />
  */
  return (
    <Layout className="layout">
      <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b96b',
        },
      }}
    >
        <Header theme={"light"} style={{
              padding: '0 0px',
            }}>
          <Menu mode="horizontal" >
            <Menu.Item key="1">
                <Link to="/" className="nav-text">Home</Link>
            </Menu.Item>
            
            <Menu.Item key="2">
                <Link to="/coloring" className="nav-text">Generator</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content style={{
              background: "#f7f7f7"
            }}>
          <Routes>
              <Route exact path="/" element={<HomePage/>} />
              <Route path="/coloring" element={<MandalaGenerator/>} />
            </Routes>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}>
          Footer
        </Footer>
      </ConfigProvider>
    </Layout>
  )
}
import { useParams } from "react-router-dom";

import './../App.css';
import React, { useState, useEffect } from 'react';
import { fabric } from 'fabric';
import { Fractal } from '../fractal.js'
import { MandalaRing } from '../MandalaRing.js'
import { Button, Col, InputNumber, Row, Slider, Select, Space, Tabs, Collapse, Radio, Switch, Divider } from 'antd';
import { jsPDF } from "jspdf";

import PedalSvg from '../assets/svg/pedal.svg';
import DoublePedalSvg from '../assets/svg/pedal.svg';
import DoubleSvg from '../assets/svg/double.svg';
import DiffSvg from '../assets/svg/diff.svg';
import FlowerishSvg from '../assets/svg/flowerish.svg';
import HoleSvg from '../assets/svg/hole.svg';
import MultiSvg from '../assets/svg/multi.svg';
import ThreeDotSvg from '../assets/svg/threedots.svg';
import TripleSvg from '../assets/svg/triple.svg';
import TripleTriSvg from '../assets/svg/tripletri.svg';
import WeirdSvg from '../assets/svg/weird.svg';

const { Panel } = Collapse;

const {  SettingOutlined  } = '@ant-design/icons';
const MandalaGenerator = () => {
  
  const doc = new jsPDF();
  const [pagesToPrint, setPagesToPrint] = useState([]);
  const [theRings, setRingsData] = useState([]);

  const [inputXValue, setInputXValue] = useState(1);
  const [inputYValue, setInputYValue] = useState(2);
  const [inputRadiusValue, setInputRadiusValue] = useState(2.22);
  const [startRadius, setstartRadiusValue] = useState(50);
  const [rotateEveryOtherRing, setRotateEveryOtherRing] = useState(false);
  const [sizeMultiplierOfObjects, setSizeMultiplierOfObjects] = useState(3);
  const [countOfRings, setnumberOfRings] = useState(4);
  const [increaseObjectCountInterval, setIncreaseInterval] = useState(4);
  const [objectsPerRing, setObjectsPerRing] = useState([1, 15]);
  const [alternatingShapes, setalternatingShapes] = useState(true);
  const [layerSeparatorConfig, setLayerSeparatorConfig] = useState("always");
  const [lastLayerSeparatorConfig, setLastLayerSeparatorConfig] = useState(true);
  const [shape, setShapeValue] = useState("circle");

  var numberOfPages = 10;
  var numberOfPagesToGo = 10;

  const typesOfRings = [
    {id: "ellipse", path: ""}, 
    {id: "circle", path: ""}, 
    {id: "pedal", path: PedalSvg}, 
    {id: "doublepedal", path: DoublePedalSvg}, 
    {id: "double", path: DoubleSvg}, 
    {id: "diff", path: DiffSvg}, 
    {id: "flowerish", path: FlowerishSvg}, 
    {id: "hole", path: HoleSvg}, 
    {id: "multi", path: MultiSvg}, 
    {id: "threedot", path: ThreeDotSvg}, 
    {id: "triple", path: TripleSvg}, 
    {id: "tripletri", path: TripleTriSvg}, 
    {id: "weird", path: WeirdSvg}];
  
  let addSeparationLayers = true;
 

  const setthePagesToPrint = (newValue) => {
    console.log(newValue)
    console.log(pagesToPrint)
    setPagesToPrint(newValue);
    console.log(pagesToPrint)
  };
  const onRingsChange = (newValue) => {
    console.log(newValue)
    setRingsData(newValue);

    console.log(theRings)
  };
  
  const onXChange = (newValue) => {
    setInputXValue(newValue);
  };
  const changeRotateEveryOtherRing = (newValue) => {
    setRotateEveryOtherRing(newValue);
  };
  const onalternatingShapesChange = (newValue) => {
    setalternatingShapes(newValue);
  };
  const onObjectsPerRingChange = (newValue) => {
    setObjectsPerRing(newValue);
  };
  const onIncreaseIntervalChange = (newValue) => {
    setIncreaseInterval(newValue);
  };
  const onRadiusChange = (newValue) => {
    setInputRadiusValue(newValue);
  };
  const onNumberOfRingsChange = (newValue) => {
    setnumberOfRings(newValue);
  };
  const onStartRadiusChange = (newValue) => {
    setstartRadiusValue(newValue);
  };
  const onSizeOfObjectsChange = (newValue) => {
    setSizeMultiplierOfObjects(newValue);
  };
  const onLastLayerSeparatorConfigChange = (newValue) => {
    setLastLayerSeparatorConfig(newValue.target.value);
  };
   const onLayerSeparatorConfigChange = (newValue) => {
    console.log(newValue.target.value)
    setLayerSeparatorConfig(newValue.target.value);
  };
  


  

  const onShapeChange = (value) => {
    console.log(`selected ${value}`);
    setShapeValue(value)
  };

  const onModeChange = (value) => {
    console.log(`selected ${value}`); 
  };

  const onTypeChange = (value) => {
    console.log(`selected ${value}`);
  };
  
  const genExtra = () => (
    <div
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    >
      p
    </div>
  );

 


  

 const [canvas, setCanvas] = useState('');
 useEffect(() => {
   setCanvas(initCanvas());
 }, []);



 const initCanvas = () => (
   new fabric.Canvas('canvas', {
     height: 3510,
     width: 2490,
     backgroundColor: "#FFFFFF"
     
   })

   
 )

 // Similar to componentDidMount and componentDidUpdate:
 useEffect(() => {
  if(canvas) {
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
    canvas.on('object:scaling', (e) => {
      console.log(e)
      var o = e.target;
      if (!o.strokeWidthUnscaled && o.strokeWidth) {
        console.log("!!!!!!!!!€€€€!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        o.strokeWidthUnscaled = o.strokeWidth;
      }
      if (o.strokeWidthUnscaled) {
        console.log("!!!!!!!!!€€%%%%%%%%%%%%%%%%%%%%!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        o.strokeWidth = o.strokeWidthUnscaled / o.scaleX;
      }
    })
  }
  
  });




 const randomize = () => {
  canvas.clear();
  let canvasmaxwidth = document.getElementById("canvas-side-container-inner");
  console.log(canvasmaxwidth)

  let background = new fabric.Rect({
    //fill: "#1677ff80",
    fill: "white",
    hoverCursor: "pointer",
    originX:"left",
    originY:"top",
    opacity: 1,
    height:3510,
    width:2490,
    selectable: false,
    strokeWidth: 0,
    stroke: "white",
    zIndex:0
  });   

  canvas.add(background)

  var numberOfRings = countOfRings;
  var alternatingShapesOnRings = alternatingShapes;


  let rings = [];
  console.log(theRings)
  onRingsChange(rings)
  let startX = canvas.width/2;
  let startY = canvas.height/2;
  let startSize = startRadius;
  var objectCount = getRandomInt(objectsPerRing[0],objectsPerRing[1])

  for(var i = 0;i < numberOfRings;i++){
    
    let objectSize = Math.PI*startSize*(i+1)/objectCount*4;

    if(alternatingShapesOnRings && !isEven(objectCount)) {
      objectCount+=1;
    }

    if(i % increaseObjectCountInterval == 0 ) {
      objectCount= objectCount*2;
    }

    let firstShape = Math.floor(Math.random()*typesOfRings.length)
    let secondShape = Math.floor(Math.random()*typesOfRings.length)
    while(firstShape == secondShape) {
      secondShape = Math.floor(Math.random()*typesOfRings.length)
    }

    var shapes = [
      typesOfRings[firstShape].id,
      typesOfRings[secondShape].id
    ]

    let startDegrees = 0;

    let diameter = startSize;
    if(i>0) {
      //console.log("diameter = rings[i-1].size+rings[i-1].objectSize")
      //console.log(diameter = rings[i-1].size+rings[i-1].objectSize)
      console.log(rings[i-1].size)
      //tiheä = = startSize+rings[i-1].objectSize*(i+1)/2;
      //alkuperäinen melko ilmava  = = diameter = rings[i-1].size++rings[i-1].objectSize*(i+1)/2;
      diameter = startSize+rings[i-1].objectSize*(i+1)/2;
      if(rotateEveryOtherRing && i % 2 == 0){
        startDegrees = 0;
      } else {
        startDegrees = 360/rings[i-1].objectCount/2;
      }
      
    }
    var ring = MandalaRing(
      startX,
      startY,
      diameter,
      shapes,
      objectSize,
      objectCount,
      startDegrees

      )
      rings.push(ring);
  }

  //let fractal = Fractal(startRadius,inputXValue,inputYValue, inputRadiusValue, startX, startY)
  console.log(rings)
  onRingsChange(rings)
  console.log(theRings)
  //drawFractal(fractal)
  setTimeout(() => {
    console.log(theRings)
    setTimeout(() => {
      let r = theRings;
      console.log(r)
      drawMandala(rings);
    },2000);
  
  }, 1000);
  
};



function compare( a, b ) {
  if ( a.zIndex < b.zIndex ){
    return -1;
  }
  if ( a.zIndex > b.zIndex ){
    return 1;
  }
  return 0;
}

var customEvtHandler = function (evt) {
    //console.log("I was triggered by a custom event.");
    //console.log(evt.target);
  };

const drawMandala = (ringsArray) => {

  let rings = ringsArray;
  console.log(ringsArray)
  console.log(rings)
  let z = 0;
  
  rings.slice().reverse().forEach(function (ring) { 
    //startX, startY, size, shape, angle, distance
    //locationX, locationY, size, type, objectSize, objectCount

    //console.log("AAaaaaAAAa")
    //console.log(ring.size)
    //console.log(z)
   //if(z<rings[rings.length-1].objectCount+rings[rings.length-2].objectCount) {
      ///console.log("################################################################################################")
      drawPedalRing(ring.locationX, ring.locationY, ring.objectSize, ring.shape, 360/ring.objectCount, ring.size, z, true, ring.objectCount, ring.startDegrees)
      //var currentRing = generatePedalRing(ring.locationX, ring.locationY, ring.objectSize, ring.shape, 360/ring.objectCount, ring.size, z, true, ring.objectCount, ring.startDegrees)
    //}
    z=z+ring.objectCount+1;
    //objects.push(currentRing)
  })
  //console.log(canvas)
  //console.log(canvas._objects)
  //console.log(objects)
  //drawMandalaFromObject(objects)
    
  
 
  setTimeout(() => {
    canvas._objects.sort(compare);

    const dataURL = canvas.toDataURL({
      width: canvas.width,
      height: canvas.height,
      left: 0,
      top: 0,
      format: 'jpg',
    });
    //document.getElementById("downloadjpg").link = dataURL;

    setTimeout(() => {
      if(!lastLayerSeparatorConfig) {
        canvas.remove(canvas._objects(canvas._objects.length-1))
      }
      canvas.renderAll(); 
      
    }, 10);
    console.log(canvas._objects)
  }, 500);

  //canvas._objects.sort( compare );
    //canvas.renderAll();
  //console.log(canvas._objects)
};


const drawFlower = () => {
  canvas.clear();
  let startX = canvas.width/2;
  let startY = canvas.height/2;
  let angle = 30;
  let startLength = 200;
  let distance = 200;

  console.log("df")

  drawPedalRing(startX, startY, startLength, "ellipse", angle, 10)
  drawPedalRing(startX, startY, startLength, "ellipse", angle, 100)
  drawPedalRing(startX, startY, startLength, "ellipse", angle, 300)
}

function isEven(n) {
  return n % 2 == 0;
}

const drawMandalaFromObject = (arrayOfRings) => {
  console.log(arrayOfRings)

  var canvasObjectGroups = []

  arrayOfRings.slice().reverse().forEach(function (ring) { 
    var currentGroup = []
    ring.forEach(function(pedal) {
      var currentObject = generateaPedal(pedal.x, pedal.y, pedal.size, pedal.shape, pedal.angle, pedal.color)
      currentGroup.push(currentObject)
    })
    console.log(currentGroup)
    var group = new fabric.Group(currentGroup)
    canvas.add(group);
    canvasObjectGroups.push(group)

    console.log(canvasObjectGroups)
  })

  
}
const drawPedalRing = (startX, startY, size, shapes, angle, distance, zindex = 1, drawSeparator = true, objectCount, startingDegrees ) => {
  var distanceFromMiddle = distance;
  var currentAngle = startingDegrees;


    
  for(let i = 0; i <= objectCount;i++) {
    currentAngle = angle*i+startingDegrees;
    let currentShape;
    if(true){
      if(isEven(i)){
        currentShape = shapes[0];
      } else {
        currentShape = shapes[1];
      }
    }

    
    if(currentAngle == 0 || currentAngle % 360 == 0){
      drawaPedal(startX+distanceFromMiddle, startY, size, currentShape, currentAngle, "black", zindex+i)
    } else if(currentAngle > 0 && currentAngle < 90){
      //console.log(Math.sin(currentAngle* Math.PI/180)*distanceFromMiddle)
      //console.log(Math.cos(currentAngle* Math.PI/180)*distanceFromMiddle)
      drawaPedal(
        startX+Math.cos(currentAngle* Math.PI/180)*distanceFromMiddle, 
        startY+Math.sin(currentAngle* Math.PI/180)*distanceFromMiddle, size, currentShape, currentAngle, "black", zindex+i)
    } else if(currentAngle == 90) {
      drawaPedal(startX, startY+distanceFromMiddle, size, currentShape, currentAngle, "black", zindex+i)
    } else if(currentAngle > 90 && currentAngle < 180) {
      drawaPedal(startX-Math.sin((currentAngle -90)* Math.PI/180)*distanceFromMiddle, startY+Math.cos((currentAngle -90)* Math.PI/180)*distanceFromMiddle, size, currentShape, currentAngle, "black", zindex+i)
    } else if(currentAngle > 180 && currentAngle < 270) {
      drawaPedal(startX-Math.cos((currentAngle -180)  * Math.PI/180)*distanceFromMiddle, startY-Math.sin((currentAngle -180) * Math.PI/180)*distanceFromMiddle, size, currentShape, currentAngle, "black", zindex+i)
    } else if(currentAngle > 270 && currentAngle < 360) {
      drawaPedal(startX+Math.sin((currentAngle -270)  * Math.PI/180)*distanceFromMiddle, startY-Math.cos((currentAngle -270) * Math.PI/180)*distanceFromMiddle, size, currentShape, currentAngle, "black", zindex+i)
    } else if(currentAngle == 180) {
      drawaPedal(startX-distanceFromMiddle, startY, size, currentShape, currentAngle, "black", zindex+i)
    } else if(currentAngle == 270) {
      drawaPedal(startX, startY-distanceFromMiddle, size, currentShape, currentAngle, "black", zindex+i)
    }
  }

  //let group = new fabric.Group(ringobj)
  //canvas.add(group)
  console.log(layerSeparatorConfig)
  console.log(layerSeparatorConfig == "always")
  if(layerSeparatorConfig == "always") {
    if(shapes[0] == "tripletri" || shapes[1] == "tripletri") {
      drawCircle(startX, startY, distance, zindex+objectCount+1 )
    } 
    else if(shapes[0] == "tripletri" || shapes[1] == "tripletri") {
      drawCircle(startX, startY, distance-size/2, zindex+objectCount+1 )
    } 
    else {
      drawCircle(startX, startY, distance, zindex+objectCount+1 )
    }
  } else if(layerSeparatorConfig == "random" && Math.random() < 0.5) {
    if(shapes[0] == "tripletri" || shapes[1] == "tripletri") {
      drawCircle(startX, startY, distance, zindex+objectCount+1 )
    } 
    else if(shapes[0] == "tripletri" || shapes[1] == "tripletri") {
      drawCircle(startX, startY, distance-size/2, zindex+objectCount+1 )
    } 
    else {
      drawCircle(startX, startY, distance, zindex+objectCount+1 )
    }
  }
  
  
}

const generatePedalRing = (startX, startY, size, shapes, angle, distance, zindex = 1, drawSeparator = true, objectCount, startingDegrees ) => {
  var distanceFromMiddle = distance;
  var currentAngle = startingDegrees;

  let ringobj = [];

    
  for(let i = 0; i <= objectCount;i++) {
    currentAngle = angle*i+startingDegrees;
    let currentShape;
    if(true){
      if(isEven(i)){
        currentShape = shapes[0];
      } else {
        currentShape = shapes[1];
      }
    }

    var currentpedal;
    currentpedal = { 
      size: size, 
      shape: currentShape, 
      angle: currentAngle, 
      color: "black", 
      z: zindex+i}
    if(currentAngle == 0 || currentAngle % 360 == 0){

      //x, y, size, shape, angle,color, zindex = 1
      currentpedal.x = startX+distanceFromMiddle
      currentpedal.y = startY

    } else if(currentAngle > 0 && currentAngle < 90){
      //console.log(Math.sin(currentAngle* Math.PI/180)*distanceFromMiddle)
      //console.log(Math.cos(currentAngle* Math.PI/180)*distanceFromMiddle)
      currentpedal.x = startX+Math.cos(currentAngle* Math.PI/180)*distanceFromMiddle
      currentpedal.y = startY+Math.sin(currentAngle* Math.PI/180)*distanceFromMiddle


    } else if(currentAngle == 90) {
      currentpedal.x = startX
      currentpedal.y = startY+distanceFromMiddle
    } else if(currentAngle > 90 && currentAngle < 180) {
      currentpedal.x = startX-Math.sin((currentAngle -90)* Math.PI/180)*distanceFromMiddle
      currentpedal.y = startY+Math.cos((currentAngle -90)* Math.PI/180)*distanceFromMiddle
      

    } else if(currentAngle > 180 && currentAngle < 270) {
      currentpedal.x = startX-Math.cos((currentAngle -180)  * Math.PI/180)*distanceFromMiddle
      currentpedal.y = startY-Math.sin((currentAngle -180) * Math.PI/180)*distanceFromMiddle

    } else if(currentAngle > 270 && currentAngle < 360) {
      currentpedal.x = startX+Math.sin((currentAngle -270) * Math.PI/180)*distanceFromMiddle
      currentpedal.y = startY-Math.cos((currentAngle -270) * Math.PI/180)*distanceFromMiddle
    } else if(currentAngle == 180) {
      currentpedal.x = startX-distanceFromMiddle
      currentpedal.y = startY

      
    } else if(currentAngle == 270) {
      currentpedal.x = startX
      currentpedal.y = startY-distanceFromMiddle

      
    }
    //console.log(currentpedal)
    ringobj.push(currentpedal)
  }

  console.log(ringobj)
  //let group = new fabric.Group(ringobj)
  //canvas.add(group)

  return ringobj;
  
}

const generateaPedal = (x, y, size, shape, angle,color) => {
  //console.log("Z: "+ zindex)
  var objectsFilled = false;
  let fillColor = "#fff0"
  if(objectsFilled) {
    fillColor = "white"
  }

  var theObject;
  if(shape == "ellipse") {
    theObject = new fabric.Ellipse({
      left: x,
      top: y,
      rx: size,
      ry: size/2,
      originX:"center",
      originY:"center",
      strokeWidth: 1,
      stroke: color,
      fill: fillColor,
      angle:angle,
      selectable: false
    });
    //theObject.moveTo(zindex);
    console.log(theObject)
    return theObject;
    
  } else if(shape == "pedal" || shape == "doublepedal" || shape == "double" || shape == "diff" || shape == "flowerish"
   || shape == "hole" || shape == "multi" || shape == "threedot" || shape == "triple" || shape == "tripletri" || shape == "weird" ){
    let svgg = typesOfRings.filter(obj => {
      return obj.id === shape
    })
    
    theObject = fabric.loadSVGFromURL(svgg[0].path, function(objects, options) {
      let theSvg;
      
      objects.forEach(function(svg) {
         svg.set({
            top: y,
            left: x,
            originX:"center",
            originY:"center",
            strokeWidth: 1,
            stroke: color,
            fill: fillColor,
            selectable: false,
            dirty:true,
            rx: size,
            ry: size,
            angle:angle
         });
         //canvas.add(svg);
         //svg.scaleToWidth(size);
         //svg.scaleToHeight(size);
         /**if(angle!= 0 && angle != 360) {
          svg.rotate(angle);
         }*/
         //svg.strokeWidth = 1 / svg.scaleX;
         theSvg = svg;
         //console.log(svg.strokeWidth)
         //console.log(svg.strokeWidthUnscaled)
         //console.log(svg.scaleX)
         //svg.moveTo(zindex);
         console.log(theSvg)
         //console.log(theObject)
      });
      console.log(theSvg)


      return theSvg;
   }); 

   console.log(theObject)
   return theObject;
   
  } else {
    theObject = new fabric.Circle({
      left: x,
      top: y,
      radius:size,
      originX:"left",
      originY:"center",
      strokeWidth: 1,
      stroke: color,
      fill: fillColor,
      angle:angle,
      selectable: false
    });
    //theObject.moveTo(zindex);
    return theObject;
  }
  return theObject;
  
  //canvas.add(obj2)
}



const drawaPedal = (x, y, size, shape, angle,color, zindex = 1) => {
  //console.log("Z: "+ zindex)
  var objectsFilled = false;
  let fillColor = "#fff0"
  if(objectsFilled) {
    fillColor = "white"
  }

  var theObject;
  if(shape == "ellipse") {
    theObject = new fabric.Ellipse({
      left: x,
      top: y,
      rx: size,
      ry: size/2,
      originX:"center",
      originY:"center",
      strokeWidth: 1,
      stroke: color,
      fill: fillColor,
      angle:angle,
      selectable: false,
      zIndex:zindex
    });
    canvas.add(theObject)
    //theObject.moveTo(zindex);
    console.log(theObject)
    return theObject;
    
  } else if(shape == "pedal" || shape == "doublepedal" || shape == "double" || shape == "diff" || shape == "flowerish"
   || shape == "hole" || shape == "multi" || shape == "threedot" || shape == "triple" || shape == "tripletri" || shape == "weird" ){
    let svgg = typesOfRings.filter(obj => {
      return obj.id === shape
    })
    
    fabric.loadSVGFromURL(svgg[0].path, function(objects, options) {
      
      objects.forEach(function(svg) {
         svg.set({
            top: y,
            left: x,
            originX:"center",
            originY:"center",
            strokeWidth: 1,
            stroke: color,
            fill: fillColor,
            selectable: false,
            zIndex:zindex,
            dirty:true
         });
         canvas.add(svg);
         svg.scaleToWidth(size);
         svg.scaleToHeight(size);
         if(angle!= 0 && angle != 360) {
          svg.rotate(angle);
         }
         svg.strokeWidth = 1 / svg.scaleX;
         
         //console.log(svg.strokeWidth)
         //console.log(svg.strokeWidthUnscaled)
         //console.log(svg.scaleX)
         //svg.moveTo(zindex);
         //console.log(svg)
         theObject = svg;
         //console.log(theObject)
      });
      //console.log(theObject)
      return theObject;
   }); 
  } else {
    theObject = new fabric.Circle({
      left: x,
      top: y,
      radius:size,
      originX:"left",
      originY:"center",
      strokeWidth: 1,
      stroke: color,
      fill: fillColor,
      angle:angle,
      selectable: false,
      zIndex:zindex
    });
    canvas.add(theObject)
    //theObject.moveTo(zindex);
    return theObject;
  }
  return theObject;
  
  //canvas.add(obj2)
}



const drawCircle = (x, y, radius, zindex = 1) => {
  console.log("Z circle: "+ radius)
  let circle = new fabric.Circle({
    //fill: "#1677ff80",
    fill: "white",
    hoverCursor: "pointer",
    left: x,
    originX:"center",
    originY:"center",
    opacity: 1,
    radius:radius,
    selectable: false,
    strokeWidth: 1,
    stroke: "black",
    top: y,
    zIndex:zindex
  });   

  canvas.add(circle)
};
const downloadCurrentPagePDF = () => {
  const dataURL = canvas.toDataURL({
    width: canvas.width,
    height: canvas.height,
    left: 0,
    top: 0,
    format: 'jpg',
  });
  
  let canvasSvg = canvas.toSVG();
  console.log(canvasSvg)

  var width = doc.internal.pageSize.getWidth();
var height = doc.internal.pageSize.getHeight();

  doc.addImage(dataURL, 'JPEG', 0,0, width, height)
  //doc.addSvgAsImage(canvasSvg, 0,0, 1500,1500)
  doc.save("test.pdf")
};


const setComplexity = (complexityLevel) => {
  if(complexityLevel == 1) {
    
    setRotateEveryOtherRing()
    setstartRadiusValue()
    setSizeMultiplierOfObjects()
    setnumberOfRings()
    setIncreaseInterval()
    setObjectsPerRing()
    setalternatingShapes()
    setLayerSeparatorConfig()
    setLastLayerSeparatorConfig()

  } else if(complexityLevel == 2) {

  } else {

  }
  
}

const addAPagetoPdfandDownload = (randomizeNext) => {
  let pages = pagesToPrint;

  if(pages.length==1) {pages.length = 0}

  let dataURL = canvas.toDataURL({
    width: canvas.width,
    height: canvas.height,
    left: 0,
    top: 0,
    format: 'jpeg',
  });
  
  console.log(canvas.width + " // " + canvas.height)
  console.log(dataURL)

  var width = doc.internal.pageSize.getWidth();
  var height = doc.internal.pageSize.getHeight();

  //doc.addPage()
  pages.push(dataURL)
  console.log(pages)
  setthePagesToPrint(pages)
  //doc.addImage(dataURL, 'JPEG', 0,0, width, height)
  console.log("docdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdocdoc")
  console.log(doc)

  savePDF()

  setthePagesToPrint([])

}
const addAPagetoPdf = (randomizeNext) => {
    console.log(pagesToPrint)
    let pages = pagesToPrint;

      let dataURL = canvas.toDataURL({
        width: canvas.width,
        height: canvas.height,
        left: 0,
        top: 0,
        format: 'jpg',
      });
      
    
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();
    
      //doc.addPage()
      pages.push(dataURL)
      console.log(pages)
      setthePagesToPrint(pages)
      //doc.addImage(dataURL, 'JPEG', 0,0, width, height)
     
      if(randomizeNext){
        setTimeout(()=>{
          randomize()
        },300)
      }
      
        
  
}

const savePDF = () => {
  var width = doc.internal.pageSize.getWidth();
  var height = doc.internal.pageSize.getHeight();
  let i = 0;
  pagesToPrint.forEach(page => {

      if(i!=0) doc.addPage()
      doc.setFillColor("#FFFFFF");
      doc.addImage(page, 'JPEG', 0,0, width, height)
      i++;
  })
  doc.save("test.pdf")

}


/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

 return(
  <div>
    <div class="loading-overlay" id="loading">
      <div class="loading-overlay-container">
        <div class="loading-overlay-container-inner">
          <h3>Generating a PDF to download</h3>
          <h5>This free app is kept free with ads. Clicking these ads doesn't stop the progress, but helps us!</h5>
        </div>
      </div>
    </div>
  
    <Row className="fractals-container">
      
        
      <Col span={12}>
        <div class="mandala-settings-container">
        <h1>Configurations</h1>
          <div class="mandala-settings-container-inner">
            <Tabs
          defaultActiveKey="1">
              <Tabs.TabPane tab="Simple" key="1">
                <div class="configuration-mode-container" id="standard-configurations">
                  <h3>Mandala complexity level</h3>
                  <div class="mandala-type-selection">
                    <Radio.Group onChange={setComplexity} defaultValue="a">
                      <Radio.Button value="1">Simple</Radio.Button>
                      <Radio.Button value="2">Standard</Radio.Button>
                      <Radio.Button value="3">Complex</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Advanced" key="2">
              <div class="configuration-mode-container" id="advanced-configurations">
              <div class="config-item-container">
                <h3>Start radius</h3>
                <Row>
                  <Col span={12}>
                  <Slider
                      min={0}
                      max={200}
                      step={1}
                      onChange={onStartRadiusChange}
                      value={typeof startRadius === 'number' ? startRadius : 0}
                    />
                  </Col>
                  <Col span={4}>
                    <InputNumber
                      min={0}
                      max={200}
                      style={{
                        margin: '0 16px',
                      }}
                      value={startRadius}
                      onChange={onStartRadiusChange}
                    />
                  </Col>
                </Row>
              </div>
              <Divider></Divider>
              <div class="config-item-container">
                <h3>Have alternating shapes</h3>
                <Row>
                  <Col>
                    <Switch defaultChecked onChange={onalternatingShapesChange} />
                  </Col>
                </Row>
              </div>
              <Divider></Divider>
              <div class="config-item-container">
                <h3>When to draw layer separators</h3>
                <Row>
                  <Col>
                    <Radio.Group onChange={onLayerSeparatorConfigChange} defaultValue="always">
                      <Radio.Button value="never">Never</Radio.Button>
                      <Radio.Button value="always">Always</Radio.Button>
                      <Radio.Button value="random">Randomize</Radio.Button>
                    </Radio.Group>
                  </Col>
                </Row>
              </div>
              <Divider></Divider>
              <div class="config-item-container">
                <h3>Draw last layer separator</h3>
                <Row>
                  <Col>
                    <Switch defaultChecked onChange={onLastLayerSeparatorConfigChange} />
                  </Col>
                </Row>
              </div>
              <Divider></Divider>
              <div class="config-item-container">
                <h3>Rotate every other ring</h3>
                <Row>
                  <Col>
                    <Switch onChange={changeRotateEveryOtherRing} />
                  </Col>
                </Row>
              </div>
              <Divider></Divider>
              <div class="config-item-container">
                <h3>Number of rings</h3>
                <Row>
                  <Col span={12}>
                  <Slider
                      min={1}
                      max={15}
                      step={1}
                      onChange={onNumberOfRingsChange}
                      value={typeof countOfRings === 'number' ? countOfRings : 0}
                    />
                  </Col>
                  <Col span={4}>
                    <InputNumber
                      min={1}
                      max={15}
                      style={{
                        margin: '0 16px',
                      }}
                      value={countOfRings}
                      onChange={onNumberOfRingsChange}
                    />
                  </Col>
                </Row>
              </div>
              <Divider></Divider>
              
              <div class="config-item-container">
                <h3>Objects per ring</h3>
                <Row>
                  <Col span={16}>
                  <Slider
                      range
                      min={1}
                      max={30}
                      step={1}
                      defaultValue={[4, 10]}
                      onChange={onObjectsPerRingChange}
                    />
                  </Col>
                </Row>
              </div>
              <Divider></Divider>
              
              <div class="config-item-container">
                <h3>Size multiplier of rings</h3>
                <Row>
                  <Col span={12}>
                  <Slider
                      min={1}
                      max={5}
                      step={1}
                      onChange={onSizeOfObjectsChange}
                      value={typeof sizeMultiplierOfObjects === 'number' ? sizeMultiplierOfObjects : 3}
                    />
                  </Col>
                  <Col span={4}>
                    <InputNumber
                      min={1}
                      max={5}
                      style={{
                        margin: '0 16px',
                      }}
                      value={sizeMultiplierOfObjects}
                      onChange={onSizeOfObjectsChange}
                    />
                  </Col>
                </Row>
              </div>
              
              <Divider></Divider>
              <div class="config-item-container">
                <h3>How often increase number of objects per ring</h3>
                <Row>
                  <Col span={12}>
                  <Slider
                      min={1}
                      max={15}
                      step={1}
                      onChange={onIncreaseIntervalChange}
                      value={typeof increaseObjectCountInterval === 'number' ? increaseObjectCountInterval : 4}
                    />
                  </Col>
                  <Col span={4}>
                    <InputNumber
                      min={1}
                      max={15}
                      style={{
                        margin: '0 16px',
                      }}
                      value={increaseObjectCountInterval}
                      onChange={onIncreaseIntervalChange}
                    />
                  </Col>
                </Row> 
              </div>
            </div>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      
      </Col>
      <Col span={12}>
        <div class="canvas-side-container">
        <h1>Results</h1>
          <div class="canvas-side-container-inner" id="canvas-side-container-inner">
            <div class="canvas-buttons">
              <Button  onClick={randomize}  type="primary">Generate</Button>
              <Button type="default" onClick={()=>{addAPagetoPdfandDownload()}}>Download PDF</Button>
            </div>

            <canvas class="fractal-canvas" id="canvas"  />
          </div>
        </div>
       
      </Col>
    </Row>
  </div>
 );
}
export default MandalaGenerator;
